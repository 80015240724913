html,
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

a {
  color: #1d2d7e;
}

.bg-primary {
  background-color: var(--primary) !important;
}

:root {
  --primary: #1d2d7e;
}

.btn-default {
  border: 1px solid #1d2d7e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

header .logo-header img {
  height: 32px;
  max-height: 32px;
  margin-bottom: 4px;
  padding: 0;
  line-height: 40px;
}

header .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 {
  font-weight: 300;
  color: #1b0300;
}

header .it-header-center-wrapper .it-header-center-content-wrapper .it-brand-wrapper a h2 b {
  color: #000000;
}

header .menu-barra-logo h2 {
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.5em;
  color: white;
}

header .menu-barra-logo a h2 b {
  color: white;
}

header .expanded .menu-barra-logo {
  padding: 0 24px;
}

header .expanded .menu-barra-logo h2 {
  color: black;
}

header .expanded .menu-barra-logo a h2 b {
  color: black;
}

header .menu-barra-logo {
  display: block;
}

header .menu-barra-logo.is-sticking {
  display: block;
}

footer .it-brand-wrapper .it-brand-text h2 {
  font-weight: 300 !important;
  color: #fff;
}

footer .it-brand-wrapper .it-brand-text h2 b {
  color: #fff;
}

header .it-header-center-wrapper {
  background-color: #ffffff;
}

header .pagopa-logo {
  margin-right: 1em;
}

.navbar .navbar-collapsable.expanded {
  display: block;
}

.navbar-burger {
  padding: 2px 16px;
}

footer .footer-icons {
  flex-direction: column;
}

footer .footer-icons a {
  margin-bottom: 1em;
  width: 210px;
  text-align: center;
}

.it-footer {
  padding-bottom: 32px;
  background-color: #30373d;
}

/**/
header .mr-1.logo-header {
  margin-right: 1em;
}

header .it-header-slim-wrapper-icon {
  padding-top: 6px;
  color: white;
}
header .it-header-slim-wrapper-icon i {
  margin-left: 0.6em;
}

header.is-sticky .left-sticky-header-icons {
  padding-top: 2px;
  font-size: 0.9em;
}

/***/
header .it-header-slim-wrapper {
  background: #30373d;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0px;
  height: 40px;
}

header .it-header-center-wrapper {
  background: #fff;
  height: 48px;
  display: flex;
  align-items: center;
}

header.is-sticky + main {
  margin-top: 180px;
}

a.read-more {
  text-transform: none;
}

a.read-more i {
  margin-left: 0.8em;
}

.sezione-bottoni-pagamenti i {
  margin: 25px 0;
  font-size: 80px;
}

.btn-default:hover {
  font-weight: 600;
}

.btn-default:focus,
.btn-default:active {
  background-color: #1d2d7e;
  color: #fff !important;
}

.btn-primary {
  color: #fff;
  background-color: #1d2d7e;
  border-color: #1d2d7e;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%);
}

.primo-piano-abstract {
  margin: 2em 0;
}

.sezione-altre-news {
  padding-top: 3em !important;
}

.card .it-card-footer .separator {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
}

.card-header {
  padding: 0.8em 1.25em 1em;
}

.card .card-body .category-top .data:before {
  content: none;
  display: inline-block;
  margin: 0 8px;
}

.card:after {
  content: none;
}

.category {
  font-weight: bold;
  color: #5a768a !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.carrello-elemento-row {
  justify-content: center;
}

.sezione-consulta h4 {
  font-weight: bold;
}

.sezione-consulta .btn-secondary {
  background-color: #ffffff;
  color: #1d2d7e;
  font-size: 1.2em;
}

.pos-deb-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.pos-deb-search-button {
  margin-left: 20px;
  margin-top: 14px;
}

@media (max-width: 576px) {
  .pos-deb-filter {
    flex-direction: column;
    align-items: stretch;
  }

  .pos-deb-search-button {
    margin-top: 1rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .pos-deb-filter-stati {
    font-size: small;
  }
}

.pos-deb-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.bg-lightgrey {
  background-color: #f4f4f4;
}

.bg-lightblue {
  background-color: #e6e9f2;
}

.altre-news-tags {
  margin-top: 3em !important;
}

.debug-content {
  height: 800px;
  background-color: gray;
}

.embed-responsive img {
  width: 100%;
}

.navbar .expanded .overlay {
  display: block;
}

.navbar .expanded .close-div {
  position: absolute;
}

a.relative-anchor {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

button:focus {
  outline: none;
}

.btn-minimal-padding .icon-cerca {
  height: 26px;
}
.modal .modal-dialog.modale-checkout .modal-content .modal-footer {
  background-color: #e6e9f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.totale-pagamento {
  white-space: pre-wrap;
}

.modal-footer {
  flex-wrap: wrap;
}

.modal .modal-dialog.modale-checkout .modal-content .modal-footer {
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
}

.modal .modal-dialog .modal-content .modal-header#ricerca h5 {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000000;
}

.modal .modal-dialog.modale-checkout .modal-content .modal-footer .totale-pagamento .totale-pagamento-importo {
  margin-left: 160px;
}

nav.pagination-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pagination-wrapper .paginazione-conteggio-elementi {
  width: 225px;
}

.pagination-wrapper .selezione-per-pagina {
  width: 225px;
  display: flex;
  flex-direction: row;
}

.pagination-wrapper .selezione-per-pagina .dropdown.bootstrap-select.form-select {
  width: 70px;
}

.pagination-wrapper .selezione-per-pagina.bootstrap-select-wrapper > label {
  position: relative;
  transform: translateY(0);
}

dropdown-menu inner show a,
dropdown-menu inner show a:hover {
  text-decoration: none;
}

.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a:hover span.text,
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a.selected,
.bootstrap-select-wrapper .dropdown.bootstrap-select .dropdown-menu li a.active span.text {
  text-decoration: none;
  font-weight: bold;
}

nav.pagination-wrapper .dropdown {
  margin-left: 0;
}

.modal .modal-dialog .modal-content .modal-header#riepilogo-carrello h5 {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000000;
}
.modal .modal-dialog .modal-content .modal-header#form-feedback h5 {
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: none;
}
.modal .modal-dialog .modal-content .modal-header#form-feedback .close {
  font-size: 2em;
  padding: 5px 20px;
}
#icon-button-feedback:focus{
  background-color: white !important;
}
#icon-button-feedback:hover{
  background-color: #e6e6e6 !important;
}
#rating-feedback-id > label {
  color: #faaf00;
}
.modal .modal-dialog .modal-content .modal-header .close {
  font-size: 4rem;
  padding: 0 20px;
}

#header-profilo-titolo {
  position: relative;
}

#header-profilo-logout {
  position: absolute;
  top: 0.5em;
  right: 1em;
  font-weight: 600;
}
#header-profilo-logout a:hover {
  text-decoration: none;
}

#header-profilo-menu .nav-tabs,
#header-profilo-menu .nav-tabs {
  color: #4c4c4d;
  background-color: transparent;
  border-width: 0;
}

.nav-tabs {
  overflow-x: hidden !important;
}

#header-profilo-menu .nav-tabs .nav-link.active,
#header-profilo-menu .nav-tabs .nav-item.show .nav-link {
  color: #4c4c4d;
  background-color: transparent;
  border-width: 3px;
  border-color: #b1b1b3 #b1b1b3 #4c4c4d;
}

@media (min-width: 992px) {
  .navbar .navbar-collapsable .navbar-nav li:first-child a.nav-link {
    padding: 13px 24px !important;
  }
}

@media (max-width: 375px) {
  .nav-item-list-genSearch {
    flex-direction: column;
    border-bottom: 0;
    border-left: 1px solid #dfe4f2;
  }

  .nav-item-genSearch {
    flex-direction: column;
  }

  .nav-tabs .nav-link {
    border-bottom: 0;
    border-left: 2px solid transparent;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-bottom: 0;
    border-left: 2px solid #005c99;
  }
}

@media (max-width: 425px) {
  .nav-item-list-genSearch-generale {
    flex-direction: column;
    border-bottom: 0;
    border-left: 1px solid #dfe4f2;
  }

  .nav-tabs-generale .nav-link {
    border-bottom: 0;
    border-left: 2px solid transparent;
  }

  .nav-tabs-generale .nav-link.active {
    border-bottom: 0;
    border-left: 2px solid #005c99;
  }
}

.nav-link-active {
  border-bottom: 3px solid white !important;
}

#profilo-utente h3 {
  margin-left: -15px;
}

#profilo-utente .profilo-autenticato-come {
  font-size: 1.6rem;
}

#profilo-utente .profilo-autenticato-come .autenticato-come-ruolo {
  text-transform: uppercase;
  font-weight: bold;
}

#profilo-utente .input-con-descrizione h5 {
  font-weight: bold;
}

#profilo-utente .descrizione-input {
  borderBottomColor: "#277baa";
}

@media (max-width: 991px) {
  #profilo-utente .descrizione-input {
    font-size: medium;
  }
}

@media (max-width: 576px) {
  #profilo-utente .descrizione-input {
    font-size: unset;
  }
}

.profilo-bottone-card {
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #acacac;
  border-radius: 7px;
  position: relative;
  height: 130px;
}

@media (max-width: 425px) {
  .profilo-bottone-card-div {
    margin-bottom: 1rem;
  }
}

.profilo-bottone-card-icona {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 1.5em;
}

.profilo-bottone-card-tipo {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 400;
}

.profilo-bottone-card-titolo {
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: bold;
}

@media (max-width: 991px) {
  .profilo-bottone-card {
    background-color: #ffffff;
    padding: 16px;
    border: 1px solid #acacac;
    border-radius: 7px;
    position: relative;
    height: 130px;
  }

  .profilo-bottone-card-icona {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 1.5em;
  }

  .profilo-bottone-card-tipo {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 400;
  }

  .profilo-bottone-card-titolo {
    margin-top: 0.5em;
    font-size: 0.9em;
    font-weight: bold;
  }
}

.small-row {
  align-items: center;
  justify-content: center;
}

#title-bar-search button {
  border-radius: 0;
  width: 70px;
  height: 70px;
}

#title-bar-search input {
  height: 70px;
}

.box-sportello h5 {
  font-weight: bold;
}

.card-header:hover {
  text-decoration: underline;
}

.link-underline:hover {
  text-decoration: underline;
}

.box-sportello .box-sportello-istruzioni > * {
  font-size: 1.1em!important;
  line-height: 1.5em!important;
}

.box-sportello .box-sportello-elenco {
  margin-top: 1.8em;
}

.dettaglio-pagamento h5 {
  font-weight: bold;
}

.carrello-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.carrello-info-label {
  font-weight: bold;
  padding-right: 12px;
}

.carrello-info-text {
  border: 5px solid white;
  background-color: #cde6f6;
  padding: 6px 12px;
  font-size: 0.9em;
  line-height: 1.2em;
  font-weight: 500;
  flex-grow: 1;
}

.carrello-footer {
  background-color: #e6e9f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 425px) {
  .carrello-footer {
    flex-direction: column;
    align-content: stretch;
  }

  .totale-pagamento {
    display: flex;
    justify-content: space-between;
  }
}

.carrello-footer .totale-pagamento .totale-pagamento-importo {
  margin-left: 80px;
}

.carrello-footer .totale-pagamento {
  font-weight: bold;
  font-size: 30px;
  white-space: nowrap;
}

.carrello-elemento {
  padding: 20px 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 10px;
  margin: 10px 10px 0px 10px;
}

.carrello-elemento-label {
  font-weight: bold;
  text-transform: uppercase;
}

.carrello-elemento-bottoni {
  display: flex;
  flex-direction: row;
  justify-items: end;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  column-gap: 10px;
  border-bottom: 1px solid #B1B0B0;
  border-radius: 0;
  padding-bottom: 1rem;
}

.carrello-bottoni {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.button-carrello {
  border: none;
  padding: 15px 32px;
  text-align: center;
  font-weight: 600;
  color: #5b6f82;
  background-color: #f4f4f4;
  flex-grow: 1;
}

.data-viewer-button-cancel {
  font-size: 16px !important;
  color: #d9364f !important;
}

.nowrap {
  white-space: nowrap;
}

@media (max-width: 576px) {
  .carrello-elemento-label {
    margin-bottom: 0.5px;
  }

  .carrello-elemento-bottoni {
    flex-wrap: wrap;
    margin: 0px;
    padding: 0;
    border-bottom: 0px;
  }

  .carrello-elemento-bottone-top {
    display: flex;
    width: 110%;
    margin: 0px -10px 0px -10px;
    border-radius: 0;
    outline: 1px solid #1d2d7e;
    justify-content: center;
    box-shadow: none;
  }

  .carrello-elemento-bottone-top-cancel {
    display: flex;
    width: 110% !important;
    margin: 0px -10px 0px -10px;
    border-radius: 0;
    outline: 1px solid #d9364f;
    justify-content: center;
    box-shadow: none;
  }

  .carrello-elemento-bottone-bot {
    display: flex;
    width: 110%;
    margin: 0px -10px -10px -10px;
    border-radius: 0 0 5px 5px;
    outline: 1px solid #1d2d7e;
    justify-content: center;
  }

  .carrello-elemento-bottone-bot-cancel {
    display: flex;
    width: 110% !important;
    margin: 0px -10px -10px -10px;
    border-radius: 0 0 5px 5px;
    outline: 1px solid #d9364f;
    justify-content: center;
    box-shadow: none;
  }

  .carrello-elemento {
    border: 1px solid #B1B0B0;
    border-radius: 5px;
  }
}

.carrello-elemento-totale {
  border: 1px solid #B1B0B0;
  border-radius: 3px;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: auto;
  align-items: center;
}

@media (max-width: 984px) {
  .carrello-elemento-totale {
    width: inherit;
  }
}

.stato-pagamento {
  border-radius: 3px;
  padding-left: 7px;
  padding-right: 7px;
  font-weight: bold;
  white-space: nowrap;
  color: white;
}

.box-homepage .card-header {
  text-transform: uppercase;
}

.box-homepage .card .it-card-footer .separator {
  border-top: 0px;
  justify-content: end;
}

.card-body.empty {
  padding: 5px!important;
}

.section-pagamento h5 {
  font-weight: bold;
}

.box-homepage-bottone-card {
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #acacac;
  border-radius: 7px;
  position: relative;
}

.box-homepage-bottone-card-tipo {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 400;
}

.box-homepage-bottone-card-titolo {
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: bold;
  text-transform: uppercase;
}

.box-homepage-bottone-card-bottone {
  margin-top: 2em;
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.box-homepage-bottone-card-bottone .btn-primary {
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 7px 18px 6px!important;
}

@media (max-width: 991px) {
  .it-header-wrapper .it-nav-wrapper .it-brand-wrapper {
    padding-left: 48px;
  }
}

@media (min-width: 576px) {
  .modal-dialog.modale-checkout {
    max-width: 90%;
  }
}

@media (min-width: 992px) {
  header .it-header-navbar-wrapper {
    background: #1d2d7e;
  }

  header .it-header-center-wrapper {
    height: 86px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }

  header .menu-barra-logo {
    display: none;
  }

  header .navbar .close-div {
    display: none !important;
  }
}

.header-nav-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: transparent;
}

.header-nav-list-div-mobile {
  flex-direction: column;
  justify-content: center;
  background: transparent;
}

.header-nav-list-mobile {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  background: transparent;
}

@media (min-width: 768px) {
  footer .footer-icons {
    flex-direction: row;
  }

  footer .footer-icons a {
    margin-right: 3em;
    width: auto;
  }
}

@media (max-width: 767px) {
.sezione-consulta .btn-secondary:not(.carrello-elemento-bottone-bot, .carrello-elemento-bottone-top, .carrello-elemento-bottone-bot-cancel, .carrello-elemento-bottone-top-cancel) {
    width: 100%;
  }
}

/** ERROR PAGE */
.pageNotFound {
  text-align: center;
  color: #193957;
}

.pageNotFound h2 {
  font-weight: 400;
  font-size: 2rem;
}

.pageNotFound h3 {
  text-decoration: underline;
}

.pageNotFound .backHome {
  color: #193957;
  font-weight: 700;
  box-shadow: inset 0 0 0 2px#193957;
}

.pageNotFound img {
  width: 50%;
  height: auto;
}


/** FEEDBACK */

.feedback .btn {
  color: #d9364f;
  display: flex;
  align-items: center;
}

.feedback .btn svg {
  fill: #d9364f;
  margin-right: 10px;
}

.feedback .transazione {
  border-top: 1px solid #d6dce3;
  margin-top: 2rem;
}

.feedback .transazione thead th {
  border-width: 1px;
}


button.on-click-link {
  padding: 0;
  border: none;
  background: none;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #828282;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #828282;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #828282;
}

.white-color:hover {
  color: #fff;
  text-underline: #fff;
}

.language-flag {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 2px;
  margin-top: 2px;
}


.react-select__option {
  color: black !important;
  cursor: pointer !important; 
}
.translate-color {
  margin-top: -1px;
  margin-right: 5px; 
  background-color: #30373d; 
  color: white; 
  text-align: center;
  cursor: pointer;
  border: none;
}
.react-select__single-value {
  color: white !important;
}
.react-select__control--is-focused {
  border: none !important; /* Rimuove il bordo quando è in focus */
  box-shadow: none !important; /* Rimuove l'ombra durante il focus */
  outline: none !important; /* Rimuove l'outline */
}
.react-select__control--is-focused .react-select__dropdown-indicator {
  color: white !important;
  cursor: pointer !important;
}

.react-select__control { 
  display: flex;
  align-items: center;
  background-color: #30373d !important;
  border: 1px solid #30373d !important;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer !important;
  height: 38px;
}

/** Ricerca */
@import './Portal/antd.css';

#ricercaAvanzata.selectContent {
  position: relative;
  margin-top: 6px;
}
#ricercaAvanzata.selectContent input {
  font-weight: normal!important;
  font-size: 14px!important;
}
#ricercaAvanzata.selectContent .customSelect {
  height: 2.5rem;
  width: calc(100% - 10px);
  margin: 0 5px;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selector {
  height: 2.5rem !important;
  border-bottom: 1px solid #277baa !important;
  padding: 0 0.5rem;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-search {
  left: 0.5rem;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-item,
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-search,
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2.5rem !important;
  min-height: 2.0rem;
  margin-top: 0;
  margin-bottom: 0;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-overflow {
  margin-top: -3px;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
  min-height: 1.5rem;
  line-height: 2.5rem;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-placeholder,
#ricercaAvanzata.selectContent .customSelect .ant-select-arrow {
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 400;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-clear {
  right: 25px;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-search-input {
  font-size: 1rem;
  color: #4f4f4f;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-item {
  padding-inline-end: 12px;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-item .ant-select-selection-item-content {
  margin-right: 8px;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-item .ant-select-selection-item-remove {
  color: #4f4f4f;
  font-size: 0.75rem;
}
#ricercaAvanzata.selectContent .customSelect .ant-select-selection-item .ant-select-selection-item-remove :hover {
  color: #9d0129;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .ant-select-selection-item,
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .ant-select-selection-placeholder,
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .ant-select-arrow,
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .ant-select-clear {
  color: #dbdfe3;
  opacity: 0.75;
}

/* #ricercaAvanzata.selectContent .ant-select-focused .ant-select-selector {
  border-bottom: none !important;
} */

.ant-select-item-option-content {
  white-space: pre;
}

#ricercaAvanzata.selectContent .customSelectLabel {
  position: absolute;
  top: 0;
  left: 5px;
  color: #4f4f4f;
  background-color: inherit;
  font-weight: 400;
  line-height: 2.5rem;
  transition: 0.2s ease-out;
  font-size: 1rem;
  cursor: text;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.5rem;
  width: calc(100% - 10px);
}
#ricercaAvanzata.selectContent .selectActive {
  transform: translateY(-75%);
  font-size: 0.777rem;
}
#ricercaAvanzata.selectContent .selectDisabled .ant-select-selector {
  cursor: initial !important;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .disabledSelection {
  opacity: 1;
  cursor: initial;
  border: none;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .disabledSelection .ant-select-selection-item-content {
  opacity: 1;
  color: #000000d9;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .disabledSelection .ant-select-selection-item-remove {
  display: block;
  box-shadow: none;
  background: transparent;
  border: none;
  padding: 0;
  height: 40px;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .disabledSelection .ant-select-selection-item-remove:after {
  animation: none;
}
#ricercaAvanzata.selectContent .ant-select-disabled .ant-select-selector .ant-select-selection-search input {
  cursor: initial !important;
}
.customInputDropdown {
  padding: 0 !important;
}
.customInputDropdown .ant-select-item-option {
  color: #4f4f4f;
  font-size: 1rem;
  padding: 0 24px;
  background-color: transparent;
  align-items: center;
}
.customInputDropdown .ant-select-item-option :hover {
  font-weight: bold;
  text-decoration: underline;
}
.maxTagPlaceholder {
  display: grid;
}
.maxTagPlaceholder .maxTagPlaceholderContent .maxTagPlaceholderItem {
  font-size: 1rem;
  float: left;
}
.maxTagPlaceholder .maxTagPlaceholderContent .ant-btn {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 0;
  margin-left: 10px;
  font-size: 0.75rem;
  float: right;
}
.ant-form-item-has-feedback #ricercaAvanzata.selectContent .ant-select-clear {
  right: 30px !important;
}
.readOnlyItem #ricercaAvanzata.selectContent .customSelectLabel {
  background-color: transparent;
  border-bottom: none;
}
.readOnlyItem #ricercaAvanzata.selectContent .customSelect {
  border-bottom: 1px solid #277baa;
}
.readOnlyItem #ricercaAvanzata.selectContent .customSelect .ant-select-selector {
  border-bottom: none !important;
}
.readOnlyItem #ricercaAvanzata.selectContent .customSelect .ant-select-selector .ant-select-selection-item {
  color: #4f4f4f;
}
.ant-form-item-has-error #ricercaAvanzata.selectContent .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #9d0129 !important;
}

.no-bot-margin {
  margin-bottom: 0;
}

.blue-bot-border {
  border-bottom: 1px solid #277baa;
}

.cart-counter {
  margin-left: 3px;
}

.button-no-style {
  border: none;
  background: none;
  color: currentColor;
}

.button-no-style:hover {
  text-decoration: underline;
}

.user-menu {

}

.user-menu li {
  padding: 6px;
  padding-right: 15px;
  text-wrap: none;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .modal .modal-dialog {
    margin: 0px !important;
  }
}

@media (max-width: 375px) {
  .modal .modal-dialog.modale-checkout .modal-content .modal-footer .totale-pagamento .totale-pagamento-importo {
    margin-left: 20px;
  }
}
.toolsArea {
    background-color: white;
}

.toolsArea > div > button, .toolsArea > div {
    margin-right: 15px;
}

.advancedSearch {
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border-top: 1px solid rgba(0,0,0,0.2);
    padding-top: 10px;
}
.grid {
    background: transparent;
    border: none;
    block-size: 100%;
    color: black;
}

@-moz-document url-prefix() {
    .grid {
        height: 962px;
    }
}

.grid :global(.rdg-header-row) {
    background: rgb(233, 233, 233);
    text-transform: uppercase;
}

.grid :global(.rdg-header-row) > div {
    border-inline-end: none;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.grid .row {
    background-color: white;
}

.grid .row:not(:first-child) :global(.rdg-cell) {
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    user-select: text;
}

.noselect .row:not(:first-child) :global(.rdg-cell) {
    user-select: none !important;
}